<template>
    <BaseModal
        :is-visible="modalStore.activeModals['new-login-info'] === true"
        class="new-website-info-modal"
        data-width="medium"
    >
        <template #title>Inlogged op tln.nl nu nog eenvoudiger</template>

        <template #default>
            <p>
                Na het invullen van je e-mailadres op de inlogpagina, ontvang je een eenmalige inlogcode in
                je mailbox die 15 munten geldig is. Gebruik deze code om verder in te loggen.
            </p>

            <p>
                Heb je vragen of hulp nodig bij het aanmaken van een account? Neem gerust contact met
                onze ledendesk via <a href="mailto:info@tln.nl">info@tln.nl</a> of tel 088-4567567.
            </p>

            <p style="display: flex; margin-top: 2rem;">
                <BaseButton
                    :element="NuxtLink"
                    :external="true"
                    to="/mijn-tln/login"
                    size="large"
                    icon="arrow-right"
                >
                    Login
                </BaseButton>

                <BaseButton
                    style="margin-top: 0;"
                    class="base-button--transparent-dark"
                    @click="modalStore.deactivateAllModals()"
                >
                    Sluiten
                </BaseButton>
            </p>
        </template>
    </BaseModal>
</template>

<script setup>
import { useModalStore } from '~/store/modal';

const NuxtLink = resolveComponent('NuxtLink');

const modalStore = useModalStore();
</script>
