<template>
    <transition name="fade">
        <NavigationDropdown
            v-if="navigationStore.activeDropdown === 'profile'"
            dropdown-class="navigation-dropdown--profile"
        >
            <template #default>
                <div class="navigation-dropdown__content">
                    <BaseButton
                        class="base-button--transparent-dark"
                        :element="NuxtLink"
                        to="/mijn-tln/profiel"
                        size="large"
                    >
                        Mijn profiel
                        <template #icon>
                            <BaseIcon icon="arrow-right" />
                        </template>
                    </BaseButton>
                    <BaseButton
                        v-if="user?.isProfileManager"
                        class="base-button--transparent-dark"
                        :element="NuxtLink"
                        to="/mijn-tln/organisaties"
                        size="large"
                    >
                        Mijn organisaties
                        <template #icon>
                            <BaseIcon icon="arrow-right" />
                        </template>
                    </BaseButton>
                    <BaseButton
                        class="base-button--transparent-dark"
                        :element="NuxtLink"
                        to="/mijn-tln/dossiers"
                        size="large"
                    >
                        Mijn dossiers
                        <template #icon>
                            <BaseIcon icon="arrow-right" />
                        </template>
                    </BaseButton>
                </div>
            </template>

            <template #footer>
                <BaseButton
                    class="base-button--transparent-dark"
                    size="large"
                    @click="logout"
                >
                    Uitloggen
                    <template #icon>
                        <BaseIcon icon="arrow-right" />
                    </template>
                </BaseButton>
            </template>
        </NavigationDropdown>
    </transition>
</template>

<script setup>
import { useAuthStore } from '~/store/auth';
import { useNavigationStore } from '~/store/navigation';

const NuxtLink = resolveComponent('NuxtLink');

const navigationStore = useNavigationStore();
const authStore = useAuthStore();

const logout = async() => {
    await authStore.deleteUserSession();

    reloadNuxtApp();
};

const user = computed(() => {
    return authStore.userModel;
});
</script>

<style lang="less" src="./NavigationDropdownProfile.less"></style>
